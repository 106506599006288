import { createQuery } from '@farfetched/core';
import { createEffect } from 'effector';

import { findAllProduct, FindAllProductParams, findProductById } from '@/shared/api/generated/api.generated';

export const productsQuery = createQuery({
  effect: createEffect((params: FindAllProductParams) => {
    return findAllProduct(params);
  }),
});

export const productByIdQuery = createQuery({
  effect: createEffect((id: string) => {
    return findProductById(id);
  }),
});
