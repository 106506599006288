import { createEvent, createStore, sample } from 'effector';

import { Product } from '@/shared/api/generated/api.generated';
import { notified, NotifyFxOptions } from '@/shared/lib/effector/notifications';

export const $comparisonList = createStore<Product[]>([]);
export const pushToComparisonList = createEvent<Product>();
export const deleteProductFromCompare = createEvent<string>();

sample({
  clock: pushToComparisonList,
  source: $comparisonList,

  filter: (comparisonList, comparedProduct) => {
    const product = comparisonList.find((comparisonItem) => {
      return comparisonItem.id === comparedProduct.id;
    });
    return product ? false : true;
  },
  fn: (comparisonList, comparedProduct) => {
    return [...comparisonList, comparedProduct];
  },
  target: $comparisonList,
});

sample({
  clock: deleteProductFromCompare,
  source: $comparisonList,
  fn: (compareList, id) => {
    const newCart = compareList.filter((item) => {
      return id !== item.id;
    });

    return newCart;
  },
  target: $comparisonList,
});

sample({
  clock: deleteProductFromCompare,
  fn: (): NotifyFxOptions => ({
    message: 'Товар удален из списка сравнения',
    type: 'info',
  }),
  target: notified,
});

sample({
  clock: pushToComparisonList,
  fn: (): NotifyFxOptions => ({
    message: 'Товар добавлен в список сравнения',
    type: 'info',
  }),
  target: notified,
});
