import Link from 'next/link';
import { useUnit } from 'effector-react';
import { RiHeart3Fill, RiMenuAddLine, RiShoppingCart2Line } from 'react-icons/ri';

import { $cartList } from '@/features/cart/model';
import { $comparisonList } from '@/features/compare/model';
import { $favoriteList } from '@/features/favorites/model';
import { $inputValue, onInputChange, searchProduct } from '@/features/filters/model';

import { $isAuthorized } from '@/shared/api';
import { Button } from '@/shared/components/ui/button';
import { Input } from '@/shared/components/ui/input';

import { DomStankovLogo } from '.';

export const Header = () => {
  const [searchOnSubmit, inputValue, inputChange, favoriteList, comparisonList, cartList, isAuthorized] = useUnit([
    searchProduct,
    $inputValue,
    onInputChange,
    $favoriteList,
    $comparisonList,
    $cartList,
    $isAuthorized,
  ]);

  return (
    <div className="container flex items-center justify-between gap-4 pt-4">
      <div>
        <Link href="/" className="relative flex h-12 w-[120px]">
          <DomStankovLogo className="h-full w-full" />
          <span className="sr-only">Перейти на главную</span>
        </Link>
      </div>

      <form
        className="flex flex-1 space-x-2"
        onSubmit={(event) => {
          event.preventDefault();
          searchOnSubmit({ search: inputValue });
        }}
      >
        <Input
          value={inputValue}
          onChange={(event) => inputChange(event.target.value)}
          placeholder="Поиск по сайту"
          type="search"
        />
        <Button type="submit">Поиск</Button>
      </form>

      <div className="flex">
        {/* {!isAuthorized && (
          <Button variant="ghost" className="flex items-center gap-2 " asChild>
            <Link href="/auth/signin">
              <RiAccountCircleLine />
              Войти
            </Link>
          </Button>
        )}

        {isAuthorized && (
          <Button variant="ghost" className="flex items-center gap-2 " asChild>
            <Link href="/account">
              <RiAccountCircleLine />
              Личный кабинет
            </Link>
          </Button>
        )} */}

        <Button variant="ghost" className="relative flex items-center gap-2" asChild>
          <Link href="/compare">
            <RiMenuAddLine />
            Сравнение
            {comparisonList.length > 0 && (
              <div className="absolute right-0 top-0 flex h-4 min-w-4 items-center justify-center rounded-full bg-black p-1 text-xs text-white">
                {comparisonList.length > 9 ? '9+' : comparisonList.length}
              </div>
            )}
          </Link>
        </Button>

        <Button variant="ghost" className="relative flex items-center gap-2" asChild>
          <Link href="/favorites">
            <RiHeart3Fill />
            Избранное
            {favoriteList.length > 0 && (
              <div className="absolute right-0 top-0 flex h-4 min-w-4 items-center justify-center rounded-full bg-black p-1 text-xs text-white">
                {favoriteList.length > 9 ? '9+' : favoriteList.length}
              </div>
            )}
          </Link>
        </Button>

        <Button variant="ghost" className="relative flex items-center gap-2" asChild>
          <Link href="/cart">
            <RiShoppingCart2Line />
            Корзина
            {cartList.length > 0 && (
              <div className="absolute right-0 top-0 flex h-4 min-w-4 items-center justify-center rounded-full bg-black p-1 text-xs text-white">
                {cartList.length > 9 ? '9+' : cartList.length}
              </div>
            )}
          </Link>
        </Button>
      </div>
    </div>
  );
};
