import Link from 'next/link';
import { useUnit } from 'effector-react';
import { RiAccountCircleLine, RiAlignJustify, RiHeart3Fill, RiMenuAddLine, RiShoppingCart2Line } from 'react-icons/ri';

import { $inputValue, onInputChange, searchProduct } from '@/features/filters/model';

import { Button } from '@/shared/components/ui/button';
import { Input } from '@/shared/components/ui/input';
import { Sheet, SheetContent, SheetTrigger } from '@/shared/components/ui/sheet';

import { DomStankovLogo } from '.';

export const MobileHeader = () => {
  const [searchOnSubmit, inputValue, inputChange] = useUnit([searchProduct, $inputValue, onInputChange]);

  return (
    <div className="container pt-4">
      <div className="flex justify-between gap-4">
        <div>
          <Link href="/" className="relative flex h-12 w-[120px]">
            <DomStankovLogo className="h-full w-full" />
            <span className="sr-only">Перейти на главную</span>
          </Link>
        </div>

        <Sheet>
          <SheetTrigger asChild>
            <Button variant="ghost">
              <RiAlignJustify />
              <span className="sr-only">Открыть меню</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="flex flex-col">
            {/* <Button variant="ghost" className="flex flex-col items-center " asChild>
              <Link href="/auth/signin">
                <div className="flex items-center gap-2">
                  <RiAccountCircleLine />
                  Войти
                </div>
              </Link>
            </Button> */}
            <Button variant="ghost" className="flex flex-col items-center " asChild>
              <Link href="/compare">
                <div className="flex items-center gap-2">
                  <RiMenuAddLine />
                  Сравнение
                </div>
              </Link>
            </Button>
            <Button variant="ghost" className="flex flex-col items-center " asChild>
              <Link href="/favorites">
                <div className="flex items-center gap-2">
                  <RiHeart3Fill />
                  Избранное
                </div>
              </Link>
            </Button>
            <Button variant="ghost" className="flex flex-col items-center " asChild>
              <Link href="/cart">
                <div className="flex items-center gap-2">
                  <RiShoppingCart2Line />
                  Корзина
                </div>
              </Link>
            </Button>
          </SheetContent>
        </Sheet>
      </div>

      <form
        className="col-span-2 mt-4 flex gap-2"
        onSubmit={(event) => {
          event.preventDefault();
          searchOnSubmit({ search: inputValue });
        }}
      >
        <Input
          value={inputValue}
          onChange={(event) => inputChange(event.target.value)}
          placeholder="Поиск по сайту"
          type="search"
        />
        <Button type="submit">Поиск</Button>
      </form>
    </div>
  );
};
