import { combine, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';

import { createOrderMutation } from '@/entities/order';

import { CreateOrderDto, Product } from '@/shared/api/generated/api.generated';
import { createDeclination } from '@/shared/lib/declination';
import { notified, NotifyFxOptions } from '@/shared/lib/effector/notifications';

export interface CartItem {
  product: Product;
  amount: number;
}

export const $cartList = createStore<CartItem[]>([]);
export const $productAmountInCart = combine($cartList, (list) => list.length);

export const incrementAmount = createEvent<string>();
export const decrementAmount = createEvent<string>();
export const deleteProductFromCart = createEvent<string>();
export const createOrderEvent = createEvent<CreateOrderDto['user']>();

export const changeAmount = createEvent<{ id: string; amount: number }>();

export const pushToCartList = createEvent<Product>();

sample({
  clock: pushToCartList,
  source: $cartList,

  filter: (cartList, addedProduct) => {
    const product = cartList.find((cartItem) => {
      return cartItem.product.id === addedProduct.id;
    });
    return product ? false : true;
  },
  fn: (cartList, addedProduct) => {
    return [...cartList, { product: addedProduct, amount: 1 }];
  },
  target: $cartList,
});

sample({
  clock: pushToCartList,
  fn: (): NotifyFxOptions => ({
    message: 'Товар добавлен в корзину',
    type: 'info',
  }),
  target: notified,
});

sample({
  clock: changeAmount,
  source: $cartList,

  fn: (cartList, params) => {
    const newCart = cartList.map((item) => {
      if (item.product.id === params.id) {
        return {
          ...item,
          amount: params.amount,
        };
      }

      return item;
    });

    return newCart;
  },
  target: $cartList,
});

sample({
  clock: $cartList,
  filter: (cartList) => cartList.some((item) => item.amount === 0),
  fn: (cartList) => cartList.filter((item) => item.amount > 0),
  target: $cartList,
});

sample({
  clock: incrementAmount,
  source: $cartList,
  filter: (cartList, id) => {
    const item = cartList.find((card) => card.product.id === id);
    if (!item) {
      return false;
    }

    return item.amount < item.product.stock;
  },
  fn: (cartList, id) => {
    const newCart = cartList.map((item) => {
      if (item.product.id === id) {
        return {
          ...item,
          amount: item.amount + 1,
        };
      }

      return item;
    });

    return newCart;
  },
  target: $cartList,
});

sample({
  clock: decrementAmount,
  source: $cartList,
  fn: (cartList, id) => {
    const newCart = cartList.map((item) => {
      if (item.product.id === id) {
        return {
          ...item,
          amount: item.amount - 1,
        };
      }

      return item;
    });

    return newCart;
  },
  target: $cartList,
});

sample({
  clock: deleteProductFromCart,
  source: $cartList,
  fn: (cartList, id) => {
    const newCart = cartList.map((item) => {
      if (item.product.id === id) {
        return {
          ...item,
          amount: 0,
        };
      }

      return item;
    });

    return newCart;
  },
  target: $cartList,
});

sample({
  clock: deleteProductFromCart,
  fn: (): NotifyFxOptions => ({
    message: 'Товар убран из корзины',
    type: 'info',
  }),
  target: notified,
});

const getProductDeclension = createDeclination({
  one: 'товар',
  few: 'товара',
  many: 'товаров',
});

export const $cartTotalPrice = combine($cartList, (cartList) => {
  let sum = 0;
  cartList.forEach((product) => {
    const price = product.product.price.rubleWithDiscount ?? (product.product.price.ruble as number);
    sum = sum + price * product.amount;
  });
  return sum;
});

export const $productAmount = combine($cartList, (cartList) => {
  return getProductDeclension(cartList.length);
});

sample({
  clock: createOrderEvent,
  source: $cartList,
  fn: (cart, user) => {
    const products = cart.map((item) => ({ productId: item.product.id, quantity: item.amount }));

    return { products: products, user: user };
  },
  target: createOrderMutation.start,
});

export const $orderSent = createStore(false);

sample({
  clock: createOrderMutation.finished.success,
  fn: () => true,
  target: $orderSent,
});

reset({
  clock: [createOrderMutation.finished.success],
  target: $cartList,
});
