interface DeclinationOptions {
  one: string;
  few: string;
  many: string;
}

export const createDeclination = (options: DeclinationOptions) => {
  const { one, few, many } = options;

  const dec_list = {
    0: one, // 1 комментарий
    1: few, // 4 комментария
    2: many, // 5 комментариев
  };

  return (number: number) => {
    const ost = number % 100;
    let ending = dec_list[2];

    if (ost >= 11 && ost <= 19) {
      ending = dec_list[2];
    } else {
      const i = number % 10;
      switch (i) {
        case 1:
          ending = dec_list[0];
          break;
        case 2:
        case 3:
        case 4:
          ending = dec_list[1];
          break;
        default:
          ending = dec_list[2];
      }
    }

    return number + ' ' + ending;
  };
};
