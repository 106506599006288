import { createEvent, createStore, sample } from 'effector';

import { productsQuery } from '@/entities/product/model';

import { FindAllProductParams } from '@/shared/api/generated/api.generated';
import { pagesPath } from '@/shared/config/$path';
import { navigateToFx } from '@/shared/lib/effector/router';

export const $inputValue = createStore('');

export const onInputChange = createEvent<string>();

export const searchProduct = createEvent<FindAllProductParams>();

sample({
  clock: searchProduct,
  fn: (request) => {
    return { url: pagesPath.catalog.search.$url({ query: { search: request.search! } }) };
  },
  target: navigateToFx,
});

sample({
  clock: onInputChange,
  target: $inputValue,
});

sample({
  clock: searchProduct,
  fn: () => '',
  target: $inputValue,
});

//

export const $currentCategoryId = createStore<string | null>(null);

export interface FilterCheckbox {
  type: 'checkbox';
  value: string[];
}

export interface FilterRange {
  type: 'range';
  min?: number;
  max?: number;
}

interface ChangeFilterCheckbox {
  type: 'checkbox';
  value: string;
}
type ChangeFilterRange = FilterRange;

type FilterState = Record<string, FilterCheckbox | FilterRange>;

export const $filters = createStore<FilterState>({});

export const setFilter = createEvent<{ name: string } & (ChangeFilterCheckbox | ChangeFilterRange)>();

sample({
  clock: setFilter,
  source: $filters,
  fn: (filters, filter) => {
    const isRangeFilter = filter.type === 'range';
    const isCheckboxFilter = filter.type === 'checkbox';

    if (isCheckboxFilter) {
      const appliedFilter = filters[filter.name] as FilterCheckbox | null;

      if (appliedFilter?.value.includes(filter.value)) {
        return {
          ...filters,
          [filter.name]: {
            type: filter.type,
            value: appliedFilter.value.filter((item) => item !== filter.value),
          },
        };
      }

      return {
        ...filters,
        [filter.name]: {
          type: filter.type,
          value: appliedFilter?.value ? [appliedFilter.value, filter.value].flat() : [filter.value],
        },
      };
    }

    if (isRangeFilter) {
      const appliedFilter = filters[filter.name] as FilterRange | null;

      return {
        ...filters,
        [filter.name]: {
          type: filter.type,
          min: filter.min ?? appliedFilter?.min,
          max: filter.max ?? appliedFilter?.max,
        },
      };
    }

    return filters;
  },
  target: $filters,
});

export const applyProductFilters = createEvent();

export const $page = createStore(1);

export const nextPage = createEvent();
export const prevPage = createEvent();
export const goToPage = createEvent<number>();

sample({
  clock: nextPage,
  source: $page,
  fn: (currentPage) => currentPage + 1,
  target: $page,
});

sample({
  clock: prevPage,
  source: $page,
  fn: (currentPage) => currentPage - 1,
  target: $page,
});

sample({
  clock: goToPage,
  target: $page,
});

sample({
  clock: applyProductFilters,
  source: [$filters, $currentCategoryId, $page] as const,
  fn: ([filters, categoryId, page]): FindAllProductParams => {
    const priceFilter = filters['Цена'] as FilterRange;
    const stockFilter = filters['В наличие'];

    const attributesFilter = Object.entries(filters)
      .filter(([name, value]) => {
        if (name === 'Цена' || name === 'В наличие') return false;

        if (value.type === 'checkbox') {
          if (value.value.length === 0) return false;

          return true;
        }

        if (value.type === 'range') {
          if (value.min && value.max) return false;

          return true;
        }

        return false;
      })
      .map(([name, value]) => {
        let operator = '=';
        let val = '';

        if (value.type === 'checkbox') {
          val = value.value.join(',');
        }

        if (value.type === 'range') {
          if (value.min) {
            operator = '=>';
            val = `${value.min}`;
          }

          if (value.max) {
            operator = '<=';
            val = `${value.max}`;
          }

          if (value.min && value.max) {
            operator = '<=>';
            val = `${value.min}-${value.max}`;
          }
        }

        return `${name}${operator}${val}`;
      });

    return {
      priceMin: priceFilter?.min,
      priceMax: priceFilter?.max,
      // inStock: stockFilter,
      characteristicsFilter: attributesFilter,
      categoryId: categoryId ?? undefined,
      page,
    };
  },
  target: productsQuery.refresh,
});
