import { createEvent, createStore, sample } from 'effector';

import { Product } from '@/shared/api/generated/api.generated';
import { notified, NotifyFxOptions } from '@/shared/lib/effector/notifications';

export const $favoriteList = createStore<Product[]>([]);
export const pushhToFavoriteList = createEvent<Product>();
export const deleteProductFromFavorites = createEvent<string>();

sample({
  clock: pushhToFavoriteList,
  source: $favoriteList,

  filter: (favoriteList, favoriteProduct) => {
    const product = favoriteList.find((comparisonItem) => {
      return comparisonItem.id === favoriteProduct.id;
    });
    return product ? false : true;
  },
  fn: (favoriteList, favoriteProduct) => {
    return [...favoriteList, favoriteProduct];
  },
  target: $favoriteList,
});

sample({
  clock: deleteProductFromFavorites,
  source: $favoriteList,
  fn: (favoriteList, id) => {
    const newCart = favoriteList.filter((item) => {
      return id !== item.id;
    });

    return newCart;
  },
  target: $favoriteList,
});

sample({
  clock: pushhToFavoriteList,
  fn: (): NotifyFxOptions => ({
    message: 'Товар добавлен в избранное',
    type: 'info',
  }),
  target: notified,
});

sample({
  clock: deleteProductFromFavorites,
  fn: (): NotifyFxOptions => ({
    message: 'Товар убран из избранного',
    type: 'info',
  }),
  target: notified,
});
