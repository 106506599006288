import { createMutation } from '@farfetched/core';
import { createEffect, createEvent, sample } from 'effector';

import { createOrder, CreateOrderDto } from '@/shared/api/generated/api.generated';

export const createOrderMutation = createMutation({
  effect: createEffect((createOrderDto: CreateOrderDto) => {
    return createOrder(createOrderDto);
  }),
});
