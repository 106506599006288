import { ReactNode } from 'react';
import Link from 'next/link';

import { ModalForSubscribe } from '@/features/modal-for-subscribe';

import { Button } from '@/shared/components/ui/button';
import { pagesPath } from '@/shared/config/$path';

import { Footer } from './footer';
import { Header } from './header';
import { MobileHeader } from './header/mobile-header';

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;
  return (
    <div className="flex h-full flex-col">
      <header>
        <div className="border-b-[1px] p-2">
          <div className="container">
            <div className="flex flex-col justify-between lg:flex-row">
              <div className="flex flex-col items-center space-x-4 font-bold lg:flex-row">
                <Button asChild className=" w-full lg:w-auto">
                  <Link href={pagesPath.catalog.$url()}>Каталог</Link>
                </Button>
                <a href="tel:+73433630507">+7(343) 363-05-07</a>
                <a href="mailto:zakaz@domstankov.com">zakaz@domstankov.com</a>
                <span className="font-normal">Пн-Пт: 10.00-19.00</span>
              </div>
              {/* <div>
                <ModalForSubscribe title="Обратный звонок" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="hidden lg:block">
          <Header />
        </div>
        <div className="block lg:hidden">
          <MobileHeader />
        </div>
      </header>

      <div className="flex-1 py-4">{children}</div>

      <Footer />
    </div>
  );
};
