import Image from 'next/image';
import Link from 'next/link';
import { RiMailSendLine, RiPhoneFill, RiYoutubeFill } from 'react-icons/ri';

import { ModalForSubscribe } from '@/features/modal-for-subscribe';

import { Button } from '@/shared/components/ui/button';
import { pagesPath } from '@/shared/config/$path';

export const Footer = () => {
  return (
    <footer className="mt-auto border-t">
      <div className="container grid grid-cols-1 gap-4 py-8 lg:grid-cols-12">
        <div className="flex flex-col space-y-2 lg:col-span-5">
          <h4 className="font-bold">Навигация</h4>
          <Link href="/">Главная</Link>
          <Link href={pagesPath.catalog.$url()}>Каталог</Link>
          <Link href={pagesPath.favorites.$url()}>Избранное</Link>
          <Link href={pagesPath.compare.$url()}>Сравнение</Link>
          <Link href={pagesPath.cart.$url()}>Корзина</Link>
        </div>
        <div className="flex flex-col space-y-2 lg:col-span-4">
          <h4 className="font-bold">Компания</h4>
          <Link href={pagesPath.delivery_and_payment.$url()}>Доставка и оплата</Link>
          <Link href={pagesPath.requisites.$url()}>Реквизиты</Link>
          <Link href={pagesPath.offer.$url()}>Оферта</Link>
          <Link href={pagesPath.privacy_policy.$url()}>Политика конфиденциальности</Link>
          <Link href={pagesPath.personal_data.$url()}>Согласие на обработку персональных данных</Link>
        </div>
        <div className="flex flex-col space-y-2 lg:col-span-3">
          {/* <ModalForSubscribe title="Подписка на рассылку" /> */}
          <div className="flex items-center space-x-2">
            <RiMailSendLine size="1.5em" />
            <a href="mailto:zakaz@domstankov.com">zakaz@domstankov.com</a>
          </div>
          <div className="flex items-center space-x-2">
            <RiPhoneFill size="1.5em" />
            <a href="tel:+73433630507">+7(343) 363-05-07</a>
          </div>
          <div className="flex items-center space-x-1">
            <a href="https://www.youtube.com/channel/UCuyarkO4X5E9diphoHV0ysw" className="flex" target="_blank">
              <Button size="icon-lg" variant="ghost">
                <RiYoutubeFill size="3em" color="red" />
              </Button>
            </a>
            <a href="https://vk.com/club224366530" className="flex" target="_blank">
              <Image src="/images/vk.svg" alt="vk logo" height={32} width={32} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
